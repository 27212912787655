body .thankyou-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-top: -50px;
  color: #0e578e;
}
.appsCalender-filter .appsCalender-filter-select {
  /* width: 250px; */
  position: relative;
  z-index: 111;
}
.thanks p {
  font-weight: 600;
}

.thanks {
  display: none;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 6%);
  padding: 0 20px 20px 20px;
}

.blankCol {
  width: 40px;
  display: inline-block;
}
iframe {
  border: 0;
  z-index: -10 !important;
}
.passShowInput {
  position: relative;
  display: inline-block;
  width: 100%;
}

.passShowInput .form-control {
  padding-right: 35px;
}

.passShowInput .bx {
  position: absolute;
  content: "" !important;
  display: inline-block;
  bottom: 0;
  width: 26px;
  height: 26px;
  right: 0;
  z-index: 1000;
  cursor: pointer;
}

.searchClear-btns .srch-btn {
  margin-left: 10px;
}

.authPassInputgroup {
  position: relative;
  display: flex;
}

.authPassInputgroup .form-group {
  width: 100%;
}

.authPassInputgroup .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
}

.authPassInputgroup button {
  border-radius: 0 0.25rem 0.25rem 0;
  height: 38.03px;
}

.fc-event:hover,
.fc-event-selected {
  cursor: pointer !important;
}

.fc .fc-popover {
  z-index: 10 !important;
}
.line {
  width: 2px;
  background-color: lightgrey !important;
}
.lead {
  padding: 8px;
  font-size: 1.1rem;
}

tbody > tr {
  cursor: default !important;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  position: initial !important;
}

.fc .fc-daygrid-day-bottom > a {
  background-color: #0e578e;
  padding: 4px;
  border-radius: 5px;
  color: #fff !important;
}
.fc .fc-daygrid-day-bottom > a:hover {
  background-color: #1982d2;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
}

.multiselectOpt {
  border-radius: 2px 0px 0px 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  color: #fff;
  padding-left: 6px;
  width: 70px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  text-transform: capitalize;
}

.multiselectOptClose {
  border-radius: 0px 2px 2px 0px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  background-color: hsl(0, 0%, 80%);
  text-transform: capitalize;
}
.multiselectOptClose:hover {
  color: hsl(0, 68%, 53%);
  background-color: hsl(0, 80%, 80%);
}

.multipleOpts {
  border-radius: 2px;
  font-size: 85%;
  overflow: hidden;
  padding: 2px;
  margin: 2px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  display: "flex";
  align-items: "center";
  cursor: "pointer";
  color: "#fff";
  text-transform: capitalize;
}
.multipleOpts:hover {
  transition: 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Ensure the parent container uses flexbox */
.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 1rem;
}

/* Styling the logo container */
.rdcarelogo-container {
  position: absolute;
  right: 10px;
}

/* Ensure the logo itself is correctly sized */
.parteneredwithrdcare-logo {
  height: 2rem;
  width: auto;
  margin-top: 10px;
}

.applicantStepForm-card {
  background-image: url("./background-application.jpg");
  background-size: cover;
  height: 110vh;
}
.appointmentForm-card {
  background-image: url("./background-appointment.jpg");
  background-size: cover;
  height: 110vh;
}

.opacity-transparent {
  opacity: 0.9 !important;
}

.email-container {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.email-container svg {
  font-size: 20px;
  width:18px;
  height:18px;
}
.inventorylist-footer {
  background: #0c436d;
} 
.inventory-container .row.cat-container {
  /* border-bottom: 1px solid; */
  padding: 25px 0;
}
.inventory-container .row.cat-container .cat-name {
  margin-bottom: 20px;
}
.inventory-container .row.cat-container button, button.createinventory-btn {
  margin-top: 22px;
  font-weight: bold;
  font-size: 14px;
  padding: 8px;
  margin-right:10px;
}
.inventory-container .row.cat-container button svg, button.createinventory-btn svg {
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
.item-table {
  width: 100%;
}
.item-table tr {
  height: 80px;
}
.signature-model {
  min-width: 35%;
}
.show-reportcard {
  min-width: 70%;
}

.table-action{
  padding: 20px 0;
}
.reportcard-table,.reportcard-list-table {
  width: 100%;
  margin-top: 10px;
}
.reportcard-list-table td{
  padding: 10px;
}
.reportcard-table .descheading {
  padding: 16px;
  font-size: 18px;
}

.reportcard-table .form-control,.reportcard-table .form-control:focus {
  height: 75px;
  border: none;
  background: transparent;
  font-size: 16px;
}
::-webkit-input-placeholder {
  padding-top:20px;
}
.reportcard-table thead,.reportcard-table tfoot,.reportcard-list-table thead {
  background: #0C518A;
  color:#fff;
}
.reportcard-table thead th,.reportcard-table tfoot td,.reportcard-list-table thead th {
  padding:15px;
}
.reportcard-table .trashrow-btn {
  font-size: 15px;
}
.reportcard-table .trashrow-btn svg {
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
.card-notic {
   padding: 16px 20px;
    border: solid 1px #b7aeae;
    margin: 0;
    background: #e9dddd;
    font-weight: 700;
    font-size: 15px;
    border-radius: 5px;
}
.approve-vendor-dropdown {
  width: 35%;
  float: right;
}
.order-action-btns {
  text-align: center;
}

/* Styles for Parking */
.item-1-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 2rem !important;
}

.item-1 {
  max-width: 2rem !important;
}

.item-2-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 2rem !important;
}

.item-2 {
  max-width: 2rem !important;
}

.item-3-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 25rem !important;
}

.item-3 {
  max-width: 25rem !important;
}

.item-4-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 5rem !important;
}

.item-4 {
  max-width: 5rem !important;
}

.item-5-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 5rem !important;
}

.item-5 {
  max-width: 5rem !important;
}

.item-6-th {
  background-color: #85C1E9 !important; /* Sky Blue */
  max-width: 5rem !important;
}

.item-6 {
  max-width: 5rem !important;
}


.slot-row td {
  cursor: pointer; /* Ensures that the cursor indicates a clickable element */
}

.plate-header {
  background-color: #e0f7fa !important;
}

.type-header {
  background-color: #d7ccc8 !important;
}

.slot-empty {
  background-color: #ad000073 !important;
    color: white !important;
}

.slot-occupied {
  background-color: #94ff74 !important;
}

.underground-parking-header {
  background-color: var(--blue-100) !important;
}

.surface-stall-header {
  background-color: var(--green-200) !important;
}

.hovered-item {
  background-color: #faff6e !important;
  color: black !important;
}

.signature-container {
  width: 100% !important;
  position: relative;
  height: 200px;
}

.sigCanvas {
  background: url('https://www.transparenttextures.com/patterns/aged-paper.png'); /* Example texture */
  background-color: #f6eee3;
  border: 1px solid #c4a484;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  height: 200px !important;
}
/* Mobile: Horizontal Separator */
.separator {
  width: 100%;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

/* Desktop: Vertical Separator */
.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  border: none;
}

.arrow-select {
  padding-right: 2rem !important; 
  position: relative;
}

.arrow-select.is-invalid {
  background-position: right 1.5rem center;
}
.lower-roman-list {
  list-style-type: lower-roman !important;
  padding-left: 1rem !important;
  margin-left: 0 !important;
  text-align: left !important;
}

.lower-roman-list li {
  display: list-item !important;
  text-align: left !important;
}

.lower-roman-list {
  list-style-position: inside !important;
}
