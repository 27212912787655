/* body {
    font-family: sans-serif;
    text-align: center;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(140,140,203,1) 0%, rgba(207,224,227,1) 73%);
  } */
  .calendarForm{
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 6%; */
    /* background-color: aqua; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  .calendarForm-form {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 50px 0;
    box-shadow: 0 0 25px 0 rgba(0,0,0, 5%);
  }
  .calendarForm-form h2{
    color: #0d568e;
  }
  .nmbr input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
  
 